import { createContext } from 'react';

export const CONSTANT = {
  // Menu Item Mapping Save Type
  menuItemMappingSaveChanges: 'menuItemMappingSaveChanges',
  menuItemMappingSaveDraft: 'menuItemMappingSaveDraft',

  // Group by
  groupByDay: 'groupByDay',
  groupByWeek: 'groupByWeek',
  groupByMonth: 'groupByMonth',

  // Units
  weightKGUnit: 'KG',
  weightGUnit: 'grams',
  generalCostUnit: '$',

  // Watchlist
  watchlistMaxSize: 20,

  // Food items breakdown
  numberOfTopWastedFoodItemsToDisplay: 6,
  foodItemsBreakdownSelectionMaxSize: 10,

  // Page titles
  highlightsPage: 'Highlights',
  breakdownFoodItemsPage: 'Breakdown By Food Items',
  breakdownStationsPage: 'Breakdown By Stations',
  settingsPage: 'Settings',
  contactUsPage: 'Contact Us',
  changePasswordPage: 'Change Password',
  coverInputPage: 'Cover Input',
  menuItemMappingPage: 'Menu Item Mapping',
  watchlistPage: 'Watchlist',
  wasteInputPage: 'Waste Input',

  // Chart height values
  keyOverviewChartHeight: 132,
  defaultLineChartHeight: 180,
  defaultBarChartHeight: 195,
  defaultBarLineChartHeight: 220,

  // Chart colors
  keyOverviewCoralColor: '#EB706E',
  keyOverviewGreenColor: '#64DA77',
  keyOverviewLineChartColor: ['#35CED3', '#64DA77'],
  watchlistTrendlineColor: '#35CED3',
  keyOverviewAxisColor: '#C1C0C0',
  keyOverviewGridlineColor: '#484F61',
  whiteColor: '#FFFFFF',
  defaultChartColor: '#C3D4E6',
  defaultAxisColor: '#A0A0A0',
  defaultZerolineColor: '#E0E0E0',
  defaultGridlineColor: '#EBEBEB',

  // Pastel colors for stacked bar chart
  pastelColorArray: [
    '#5FB3B3',
    '#FA9157',
    '#FAC862',
    '#98C894',
    '#6699CC',
    '#C594C5',
    '#AC7966',
    '#5784BA',
    '#EC5F67',
    '#A57283',
  ],
};

export const AppContext = createContext();
