import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LockIcon from '@material-ui/icons/Lock';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';

import { AppContext } from '../../AppContext';

const styles = (theme) => ({
  // Profile button
  profileMenuItem: {
    marginLeft: '-6px',
  },
  profileButtonStyle: {
    width: 'auto',
  },
  profileMenuPaper: {
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      backgroundImage: theme.palette.primaryGradient,
      color: 'white',
      marginTop: '-7px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      backgroundImage: theme.palette.primaryGradient,
      color: 'white',
      marginTop: '-5px',
    },
  },
  profileCompanyName: {
    ...theme.typography.h3,
  },
  profileUserName: {
    ...theme.typography.h6,
    textAlign: 'left',
  },

  // Icons
  icon: {
    color: 'white',
    cursor: 'pointer',
  },
  iconWrapper: {
    marginRight: '-10px',
  },

  // Tabs
  selectedHomeTab: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 1px 10px rgba(180, 180, 180, 0.3)',
    cursor: 'pointer',
    marginTop: '-8px',
  },
  selectedTab: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 1px 10px rgba(180, 180, 180, 0.3)',
    cursor: 'pointer',
  },
  unselectedTab: {
    cursor: 'pointer',
  },
});

class ProfileMenu extends PureComponent {
  render() {
    const {
      auth0,
      classes,
      open,
      anchorEl,
      onCloseProfileMenu,
      onClickLogout,
      onClickDirectToHomePage,
      onClickDirectToChangePasswordPage,
      onClickDirectToCoverInputPage,
      onClickDirectToMenuItemMappingPage,
      onClickDirectToWasteInputPage,
      onClickOpenProfileMenu,
      isUserAtHomePage,
      getTabClassName,
    } = this.props;
    const { impersonatorIsCompanyUser, impersonatorName, companyName, isManualInputUser } =
      this.context;
    const { user } = auth0;
    let name;
    if (user.isAdmin) {
      name = impersonatorName;
    } else {
      name = `${user.given_name} ${user.family_name}`;
    }

    return (
      <Grid item className={classes.profileMenuItem}>
        <IconButton
          color="inherit"
          aria-label="menu"
          className={`profileMenuButton ${classes.profileButtonStyle}`}
          onClick={onClickOpenProfileMenu}
        >
          <Grid container direction="column">
            <Typography className={classes.profileCompanyName}>{companyName}</Typography>
            <Typography className={classes.profileUserName}>{name}</Typography>
          </Grid>
          {open ? (
            <KeyboardArrowUpIcon style={{ fontSize: 28 }} />
          ) : (
            <KeyboardArrowDownIcon style={{ fontSize: 28 }} />
          )}
        </IconButton>
        <Menu
          id="profile-menu"
          elevation={0}
          open={open}
          anchorEl={anchorEl}
          onClose={onCloseProfileMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
          classes={{ paper: classes.profileMenuPaper }}
        >
          <MenuItem
            onClick={() => onClickDirectToHomePage('profileMenu')}
            className={isUserAtHomePage() ? classes.selectedHomeTab : classes.unselectedTab}
          >
            <ListItemIcon className={classes.iconWrapper}>
              <HomeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </MenuItem>
          {(!user.isAdmin && !user.isCompanyUser) ||
          (user.isAdmin && !impersonatorIsCompanyUser) ? (
            <div>
              <MenuItem
                onClick={() => onClickDirectToCoverInputPage('profileMenu')}
                className={`coverInputButton ${
                  getTabClassName('cover-input') ? classes.selectedTab : classes.unselectedTab
                }`}
              >
                <ListItemIcon className={classes.iconWrapper}>
                  <PeopleIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Cover Input</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => onClickDirectToMenuItemMappingPage('profileMenu')}
                className={`menuItemMappingButton ${
                  getTabClassName('menuItemMapping') ? classes.selectedTab : classes.unselectedTab
                }`}
              >
                <ListItemIcon className={classes.iconWrapper}>
                  <MenuBookIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Menu Item Mapping</ListItemText>
              </MenuItem>
            </div>
          ) : null}

          {isManualInputUser ? (
            <div>
              <MenuItem
                onClick={() => onClickDirectToWasteInputPage('profileMenu')}
                className={`wasteInputButton ${
                  getTabClassName('wasteInput') ? classes.selectedTab : classes.unselectedTab
                }`}
              >
                <ListItemIcon className={classes.iconWrapper}>
                  <FastfoodIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Waste Input</ListItemText>
              </MenuItem>
            </div>
          ) : null}

          <MenuItem
            onClick={() => onClickDirectToChangePasswordPage('profileMenu')}
            className={
              getTabClassName('change-password') ? classes.selectedTab : classes.unselectedTab
            }
          >
            <ListItemIcon className={classes.iconWrapper}>
              <LockIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </MenuItem>
          <MenuItem onClick={onClickLogout}>
            <ListItemIcon className={classes.iconWrapper}>
              <ExitToAppIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
    );
  }
}

ProfileMenu.contextType = AppContext;

export default withRouter(withAuth0(withStyles(styles)(ProfileMenu)));
